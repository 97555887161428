<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Consider each of the statements below. Which would cause a recrystallization process to be
        unsuccessful?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q9',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: 'Using a minimal amount of hot solvent to dissolve the crude sample',
          value: 'minimalHotSolvent',
        },
        {
          text: 'Allowing the solution to cool slowly after the initial heating',
          value: 'coolSlowly',
        },
        {
          text: 'Placing the solution to cool further in an ice bath for 10-15 minutes',
          value: 'coolInIceBath',
        },
        {
          text: 'Allowing the sample to warm up to room temperature after the ice bath and before filtering',
          value: 'allowSampleToWarmBeforeFiltering',
        },
      ],
    };
  },
};
</script>
